﻿import 'core-js/stable';
import 'regenerator-runtime/runtime';
import lazySizes from "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "../../css/index.scss";

import Swiper, { Navigation} from 'swiper';

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

// HMR
(function () {
    if (module.hot) {
        module.hot.accept();
    }
})();

// Code for cookie privacy policy
(function () {
    var button = document.querySelector("#cookieConsent button[data-cookie-string]");
    if (button !== null) {
        button.addEventListener("click", function (event) {
            document.cookie = button.dataset.cookieString;
        }, false);
    }
})();

// Truncate section and add read more if too tall
(function () {
    // Create Variables
    var truncate = [];
    var mxh = '';

    window.onload = function () {
        // Set Variables
        truncate = document.getElementsByClassName("truncate");

        if (truncate.length > 0) {
            mxh = window.getComputedStyle(truncate[0]).getPropertyValue('max-height');
            mxh = parseInt(mxh.replace('px', ''));

            // Add read-more button to each OSB section
            for (var i = 0; i < truncate.length; i++) {
                var el = document.createElement("button");
                el.innerHTML = "Read More";
                el.setAttribute("type", "button");
                el.setAttribute("class", "read-more hide");

                insertAfter(truncate[i], el);
            }
        }

        // Add click function to buttons
        var readMoreButtons = document.getElementsByClassName("read-more");
        for (var i = 0; i < readMoreButtons.length; i++) {
            readMoreButtons[i].addEventListener("click", function () {
                revealThis(this);
            }, false);
        }

        // Update buttons so only the needed ones show
        updateReadMore();
    }

    // Update on resize
    window.onresize = function () {
        updateReadMore();
    }

    // show only the necessary read-more buttons
    function updateReadMore() {
        if (truncate.length > 0) {
            for (var i = 0; i < truncate.length; i++) {
                if (truncate[i].scrollHeight > mxh) {
                    if (truncate[i].hasAttribute("style")) {
                        updateHeight(truncate[i]);
                    }
                    truncate[i].nextElementSibling.className = "read-more";
                } else {
                    truncate[i].nextElementSibling.className = "read-more hide";
                }
            }
        }
    }

    function revealThis(current) {
        var el = current.previousElementSibling;
        if (el.hasAttribute("style")) {
            current.innerHTML = "Read More";
            el.removeAttribute("style");
        } else {
            updateHeight(el);
            current.innerHTML = "Show Less";
        }
    }

    function updateHeight(el) {
        el.style.maxHeight = el.scrollHeight + "px";
    }

    function insertAfter(referenceNode, newNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
})();

// When page is loaded
window.onload = function () {
    // Swiper init
    const mySwiper = new Swiper('.swiper', {
        loop: true,
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            1024: {
                slidesPerView: 'auto',
                centeredSlides: true,
                slidesPerGroup: 1,
                spaceBetween: 10,
            },
        },
        observer: true,
        observeParents: true,
    })

    // LightGalllery init
    lightGallery(document.getElementById('lightgallery'), {
        selector: ('.lightgallery-item:not(.swiper-slide-duplicate)'),
        plugins: [lgZoom, lgThumbnail],
        download: false,
        zoom: true,
    });
}
